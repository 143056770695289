import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import ReactDOM from "react-dom";

interface CustomDialogProps {
    children: ReactNode;
    sx?: any; // Accept the sx prop
    open?: boolean; // Make open prop optional
    onClose?: () => void; // Make onClose prop optional
}

const Dialog: React.FC<CustomDialogProps> = ({ children, sx, open = true, onClose }) => {
    if (!open) return null; // Return null if dialog is not open

    return ReactDOM.createPortal(
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
            onClick={onClose} // Close dialog on background click
        >
            <Box
                maxWidth="sm"
                sx={{
                    m: 2,
                    p: 0,
                    height: "90%",
                    width: "90%",
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    ...sx, // Spread the sx prop styles
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </Box>
        </Box>,
        document.body
    );
};

export default Dialog;
