import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

/**
 * SoundCloud profile information type
 */
export interface SoundCloudProfileInfo {
    imageUrl: string;
    name: string | null;
    description: string | null;
    username: string | null;
    originalUrl: string;
}

/**
 * Extracts profile information from a SoundCloud profile URL using Firebase function
 * @param soundcloudUrl SoundCloud profile URL (e.g. https://soundcloud.com/penglord)
 * @returns Promise resolving to the profile information
 */
export const extractSoundCloudProfileInfo = async (soundcloudUrl: string, eventId: string): Promise<SoundCloudProfileInfo | null> => {
    try {
        // Make sure URL is properly formatted
        if (!soundcloudUrl.startsWith("http")) {
            soundcloudUrl = `https://${soundcloudUrl}`;
        }

        // Validate it's a SoundCloud URL
        if (!soundcloudUrl.includes("soundcloud.com/")) {
            throw new Error("Not a valid SoundCloud URL");
        }

        // Call the Firebase function
        const getSoundCloudProfileInfo = httpsCallable(functions, "soundcloudUtils-extractSoundCloudProfileInfo");
        const result = await getSoundCloudProfileInfo({ url: soundcloudUrl, eventId: eventId });
        const data = result.data as SoundCloudProfileInfo;

        return data;
    } catch (error) {
        console.error("Error fetching SoundCloud profile info:", error);
        return null;
    }
};

/**
 * Validates if a URL is a SoundCloud profile URL
 * @param url The URL to validate
 * @returns boolean indicating if it's a valid SoundCloud profile URL
 */
export const isSoundCloudProfileUrl = (url: string): boolean => {
    if (!url) return false;

    // Add https:// if missing
    if (!url.startsWith("http")) {
        url = `https://${url}`;
    }

    try {
        const urlObj = new URL(url);
        return urlObj.hostname === "soundcloud.com" && urlObj.pathname.length > 1;
    } catch {
        return false;
    }
};
