import { Avatar, Box, ButtonBase, Divider, Typography } from "@mui/material";
import { collection, doc, onSnapshot, orderBy, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { StorageReference, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { ChangeEvent, useContext, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { EventContext } from "../../../contexts/EventContextProvider";
import { useAuth } from "../../../contexts/UserContext";
import { privateLog } from "../../../dev/PrivateConsole";
import NoFrame from "../../../dialog/NoFrame";
import { db, storage } from "../../../firebase";
import { getLanguageText } from "../../../languagesupport";
import AddButton from "../../../shared/AddButton";
import { Member, resolveMembers } from "../../../types/bll";
import { Story } from "../../../types/dto";
import MemberComponent from "../member/MemberComponent";
import StoriesDisplay from "./stories/StoriesDisplay";
import StoriesUpload from "./stories/StoriesUpload";
import NewMemberDialog from "../../../dialog/NewMemberDialog";
import { PersonAdd } from "@mui/icons-material"; // Add this import at the top

type MemberStory = {
    member: Member;
    uploadedAt?: Date;
};
const Members = () => {
    const { event } = useContext(EventContext);
    const [members, setMembers] = useState<MemberStory[]>([]);
    const [fileURL, setFileURL] = useState<string | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [stories, setStories] = useState<Story[]>([]);
    const [openMemberStory, setOpenMemberStory] = useState<Member | undefined>(undefined);
    const { isAdmin, member, backstagePassVerified } = useAuth();

    const [openMember, setOpenMember] = useState<Member | undefined>(undefined);
    const [openNewMember, setOpenNewMember] = useState<boolean>(false);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setFileURL(url);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFileURL(undefined); // Optional: clear the URL when the dialog is closed.
    };

    React.useEffect(() => {
        if (event && members.length === 0) {
            const getMemberData = async () => {
                if (event) {
                    let _members = await Promise.all(
                        event.members.map(async (memberDoc) => {
                            return resolveMembers(memberDoc);
                        })
                    );
                    let newMembers = _members.map((member) => {
                        return { member: member } as MemberStory;
                    });
                    setMembers(newMembers);
                }
            };
            getMemberData();
        }
    }, []);

    React.useEffect(() => {
        if (event && members.length > 0) {
            const storiesCollectionRef = collection(db, "events", event.id, "stories");
            let conditions = [];

            // Filter by visibility based on 'isExclusive' flag
            if (!backstagePassVerified) {
                conditions.push(where("visibility", "in", ["public"]));
            } else {
                conditions.push(where("visibility", "in", ["public", "exclusive"]));
            }

            // You can also order the stories if needed (e.g., by upload date)
            const q = query(storiesCollectionRef, orderBy("uploadedAt", "asc"), ...conditions);

            // Listen to real-time updates
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const fetchedStories = snapshot.docs
                    .map((doc) => {
                        if (doc.data() && doc.data().uploadedAt) {
                            return {
                                id: doc.id,
                                ...doc.data(),
                                docRef: doc.ref,
                                uploadedAt: doc.data().uploadedAt.toDate(),
                            };
                        } else {
                            return undefined;
                        }
                    })
                    .filter((story) => story !== undefined) as Story[];

                // 3. Update the State
                const sortedByUploader: Story[] = [];
                const processedUploaders = new Set<string>();

                // Step 2-4: Group by uploadedBy
                fetchedStories.forEach((document) => {
                    if (!processedUploaders.has(document.uploadedBy)) {
                        processedUploaders.add(document.uploadedBy);

                        const groupedDocuments = fetchedStories.filter((doc) => doc.uploadedBy === document.uploadedBy);
                        sortedByUploader.push(...groupedDocuments);
                    }
                });

                setStories(sortedByUploader);
                // Sort reducedStories by uploadedAt in descending order before filtering for unique stories
                let reducedStories = fetchedStories
                    .map((story) => {
                        return { uploadedBy: story.uploadedBy, uploadedAt: story.uploadedAt };
                    })
                    .sort((a, b) => b.uploadedAt.getTime() - a.uploadedAt.getTime()); // Assuming uploadedAt is a string that can be compared

                let hasStory = reducedStories.filter((story, index) => reducedStories.indexOf(story) === index);

                setMembers((prevMembers) => {
                    let hasChanged = false;
                    // Transform hasStory to contain just the uploadedBy IDs for easier comparison
                    let newMembers = prevMembers.map((member) => {
                        // Find the story uploaded by this member, if any
                        const memberStory = hasStory.find((story) => story.uploadedBy === member.member.id);
                        if (memberStory && !member.uploadedAt) {
                            hasChanged = true;
                            // Include uploadedAt in the member object
                            return { ...member, uploadedAt: memberStory.uploadedAt };
                        } else if (!memberStory && member.uploadedAt) {
                            hasChanged = true;
                            // Optionally, remove uploadedAt or set it to null if the member no longer has a story
                            return { ...member, uploadedAt: undefined };
                        }
                        return member;
                    });

                    if (hasChanged) {
                        return newMembers.sort((a, b) => {
                            // Convert uploadedAt to Date objects if they are not already
                            // Assuming uploadedAt could be undefined, null, or a string that needs conversion to Date
                            let dateA = a.uploadedAt ? new Date(a.uploadedAt) : new Date(0); // Use epoch date as fallback
                            let dateB = b.uploadedAt ? new Date(b.uploadedAt) : new Date(0); // Use epoch date as fallback

                            return dateB.getTime() - dateA.getTime(); // Sort in descending order by date
                        });
                    } else {
                        return prevMembers;
                    }
                });
            });

            // Clean up the listener on component unmount
            return () => unsubscribe();
        }
    }, [backstagePassVerified, event, members]);

    if ((!isAdmin && members.length === 0) || !event) {
        return null;
    }

    const uploadStory = async (description: string, visibility: string) => {
        if (fileURL && fileInputRef.current?.files?.length) {
            let uploadedBy: string;
            if (isAdmin) {
                uploadedBy = event.id;
            } else if (member) {
                uploadedBy = member;
            } else {
                privateLog("error", "no permission to upload story");
                return;
            }
            const file = fileInputRef.current.files[0];

            // Generate a unique document ID
            const storyRef = doc(collection(db, "events", event.id, "stories"));
            const uniqueId = storyRef.id;
            let fileRef: StorageReference;
            if (isAdmin) {
                fileRef = ref(storage, `${event.id}/stories/${uniqueId}`);
            } else if (member) {
                fileRef = ref(storage, `${event.id}/stories/${member}/${uniqueId}`);
            } else {
                privateLog("error", "no permission to upload story");
                return;
            }

            // Upload the file to Firebase Storage
            const uploadTask = uploadBytesResumable(fileRef, file);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // You can add logic to show upload progress here
                },
                (error) => {
                    privateLog("error", "Upload failed:", error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(fileRef);
                    //TODO : fix sorting algorithm for stories
                    // Save the metadata to Firestore using the generated unique ID
                    await setDoc(storyRef, {
                        uploadedBy: uploadedBy,
                        filename: uniqueId, // using the generated ID as filename
                        url: downloadURL,
                        description: description,
                        uploadedAt: serverTimestamp(),
                        visibility: visibility === "eventRoom" ? "public" : "exclusive",
                        // Add other metadata as needed
                    });
                    setOpen(false);
                    privateLog("log", "Story uploaded successfully!");
                }
            );
        }
    };

    const goToMember = (m: Member) => {
        if (m.role === "event") {
            return;
        }
        //navigate("member", { state: { member: m } });
        setOpenMember(m);
    };

    const onMemberClose = () => {
        setOpenMember(undefined);
    };

    if (openMember) {
        return (
            <Box sx={{}}>
                <Divider sx={{ mt: 1, backgroundColor: "primary.main" }}></Divider>
                <MemberComponent onClose={onMemberClose} currMember={openMember}></MemberComponent>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                mt: 2,
                pb: !isMobile ? 1 : 0,
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                    width: isMobile ? "auto" : "8px",
                    height: "8px",
                    backgroundColor: "lightgrey",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "darkgrey",
                    borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "lightgrey",
                },
                // For Firefox
                "&": {
                    scrollbarWidth: isMobile ? "auto" : "thin",
                    msOverflowStyle: isMobile ? "auto" : "scrollbar",
                },
                // For IE 10+
            }}
        >
            {(isAdmin || member) && (
                <AddButton
                    onClick={() => fileInputRef.current?.click()}
                    fileInputRef={fileInputRef}
                    handleFileChange={handleFileChange}
                    text={"Upload Story"}
                />
            )}

            {openNewMember && <NewMemberDialog onClose={() => setOpenNewMember(false)} />}

            {(isAdmin || member) && open && (
                <StoriesUpload
                    closeStoriesUpload={() => setOpen(false)}
                    fileURL={fileURL}
                    onClose={handleClose}
                    uploadStory={uploadStory}
                ></StoriesUpload>
            )}
            {openMemberStory && (
                <NoFrame>
                    <StoriesDisplay
                        eventId={event.id}
                        initMember={openMemberStory}
                        members={members.map((m) => m.member)}
                        setOpenMemberStory={setOpenMemberStory}
                        stories={stories}
                    />
                </NoFrame>
            )}
            {members.map((m, key) => {
                if (m.member.role === "event") {
                    m.member.id = event.id;
                }
                return (
                    <Box
                        key={key}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: key === 0 && !isAdmin && !member ? 0 : 2,
                            alignItems: "center",
                        }}
                    >
                        <ButtonBase
                            onClick={() => {
                                m.uploadedAt ? setOpenMemberStory(m.member) : goToMember(m.member);
                            }}
                        >
                            <Avatar
                                title={m.member.name ? getLanguageText(m.member.name) : ""}
                                src={m.member.imagePath ? m.member.imagePath : undefined}
                                sx={(theme) => ({
                                    "&:hover": {
                                        backgroundColor: theme.palette.primary.main,
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            backgroundColor: "rgba(0, 0, 0, 0.5)", // change this to the color you want
                                        },
                                    },
                                    border: m.uploadedAt ? `2px solid ${theme.palette.primary.main}` : null,
                                    width: 60,
                                    height: 60,
                                    position: "relative", // Add this to position the image absolutely
                                    img: {
                                        // Add this to style the img element
                                        position: "absolute", // Position the image absolutely
                                        top: 0,
                                        left: 0,
                                        width: "100%", // Set the width to 100%
                                        height: "100%", // Set the height to 100%
                                        objectFit: "cover", // Use object-fit: cover to resize the image to fill the avatar, while preserving its aspect ratio
                                        objectPosition: "top", // Align the image towards the top
                                    },
                                })}
                            >
                                {
                                    //TODO: some bug here the name shoudl be type of string
                                    m.member.imagePath && typeof m.member.name === "string" ? (
                                        <Typography>{getLanguageText(m.member.name).slice(0, 2)}</Typography>
                                    ) : null
                                }
                            </Avatar>
                        </ButtonBase>
                        <Typography variant="body2" sx={{ p: 1, fontSize: 10 }} textAlign={"center"} key={key}>
                            {m && getLanguageText(m.member.name).slice(0, 20)}
                        </Typography>
                    </Box>
                );
            })}

            {isAdmin && (
                <AddButton
                    onClick={() => setOpenNewMember(true)}
                    text={"Add Member"}
                    icon={<PersonAdd sx={{ textAlign: "center", color: "text.primary", width: 25, height: 25 }} />}
                    sx={{ ml: 2 }}
                />
            )}
        </Box>
    );
};

export default Members;
