import { Route, Routes } from "react-router-dom";
import { Error } from "../Error";
import Account from "../features/account/Account";
import UserComponent from "../features/account/UserComponent";
import EventRoomFrame from "../features/eventRoom/frame/EventRoomFrame";
export const RouteMapping = () => {
    //We need this for displaying the user login ...
    return (
        <Routes>
            <Route path="/account" element={<Account></Account>}></Route>
            <Route path="/user" element={<UserComponent></UserComponent>}></Route>
            <Route path="/backstage/*" element={<EventRoomFrame></EventRoomFrame>}></Route>
            <Route path="/eventRoom/*" element={<EventRoomFrame></EventRoomFrame>}></Route>
            <Route path="/error" element={<Error></Error>}></Route>
        </Routes>
    );
};

export default RouteMapping;
