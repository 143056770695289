import { Box, ButtonBase, Divider, Grid, ThemeProvider, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { EventContext } from "../../../../contexts/EventContextProvider";
import IconSwitcher from "../../../../shared/IconSwitcher";
import { IconLink, IconLinkColumn } from "../../../../types/bll";
import PoweredBy from "../components/PoweredBy";
import { footerColumns } from "./FooterColumns";

export type FooterContextType = {
    columns: IconLinkColumn[];
    partnerLogoUrl: string | null;
};

export const Footer = () => {
    const { event, darkTheme } = useContext(EventContext);
    const [footer, setFooter] = useState<FooterContextType>({
        columns: footerColumns,
        partnerLogoUrl: null,
    });

    const [openFooter, setOpenFooter] = useState<boolean>(false);

    React.useEffect(() => {
        if (event && event.id !== process.env.REACT_APP_DEFAULT_EVENT_ID && footer.columns.length === 3) {
            let newColumns = [...footer.columns]; // Create a copy of the columns array
            if (event.company.socials) {
                const socialsColumn = {
                    name: event.company.name,
                    items: event.company?.socials.map((social) => {
                        return {
                            name: social.name,
                            icon: social.icon ? social.icon : social.name,
                            link: social.link,
                        } as IconLink;
                    }),
                };
                newColumns.unshift(socialsColumn); // Add the socials column at the beginning
            }
            let newFooter = {
                columns: newColumns,
                partnerLogoUrl: event.company.favicon,
            };
            setFooter(newFooter);
        }
    }, [event]);

    const getIconElement = (iconLink: IconLink, key: number) => {
        return (
            <ButtonBase
                key={key}
                onClick={() => {
                    window.open(iconLink.link, "_blank");
                }}
                sx={{ mb: 1, display: "flex", flexDirection: "row" }}
            >
                <IconSwitcher name={iconLink.icon}></IconSwitcher>
                <Typography textAlign={"left"} color={darkTheme.palette.text.primary} marginLeft={2}>
                    {iconLink.name}
                </Typography>
            </ButtonBase>
        );
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ width: "100%" }}>
                <Divider
                    sx={{
                        mt: 2,
                        width: "100%",
                        backgroundColor: "text.primary",
                    }}
                ></Divider>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        backgroundColor: "background.default",
                        alignItems: "flex-start",
                        pb: { md: 10 },
                    }}
                >
                    <Box
                        sx={{
                            flex: 2,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            {event ? (
                                <PoweredBy showDivider={false} event={event} onClick={() => setOpenFooter(!openFooter)}></PoweredBy>
                            ) : (
                                <img
                                    src="/images/logos/artegrity_logo.png"
                                    alt="mg_logo"
                                    style={{
                                        width: "100%",
                                        maxWidth: 100,
                                        height: "auto",
                                        objectFit: "contain",
                                    }}
                                ></img>
                            )}
                        </Box>
                    </Box>
                    {openFooter || !event ? (
                        <Grid container spacing={2} sx={{ flex: 5, p: 2 }}>
                            {footer.columns.map((c, key) => (
                                <Grid item key={key} xs={6} sm={4} md={3}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                        }}
                                    >
                                        <Typography color={darkTheme.palette.text.primary} fontWeight="bold" marginBottom={1} sx={{ ml: 2 }}>
                                            {c.name}
                                        </Typography>
                                        <Box>{c.items.map((i, key) => getIconElement(i, key))}</Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    ) : null}
                </Box>
                {openFooter && event?.sponsorImages ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography alignSelf={"center"} sx={{ mb: 2 }}>
                            {event.company.name} Sponsors:
                        </Typography>
                        <Grid container>
                            {event.sponsorImages.map((s, key) => (
                                <Grid item xs={6} sm={4} md={3} lg={2} key={key}>
                                    <Box component={"img"} src={s} alt={`sponsor-${key}`} sx={{ width: "100%", objectFit: "cover" }} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : null}
            </Box>
        </ThemeProvider>
    );
};
