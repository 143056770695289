import { CloudUpload, Search } from "@mui/icons-material";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Divider as MuiDivider,
    Stack,
    TextField,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { addDoc, arrayUnion, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useRef, useState } from "react";
import { EventContext } from "../contexts/EventContextProvider";
import { privateLog } from "../dev/PrivateConsole";
import { db } from "../firebase";
import { getLanguageText } from "../languagesupport";
import { IconLink, IconName, Member, MemberRoles, getIconNameFromString } from "../types/bll";
import { extractSoundCloudProfileInfo, isSoundCloudProfileUrl } from "../utils/soundcloudUtils";
import Dialog from "./Dialog";

// Custom divider component with text
const Divider = ({ text }: { text: string }) => (
    <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
        <MuiDivider sx={{ flexGrow: 1 }} />
        <Typography variant="body2" sx={{ mx: 2, color: "text.secondary" }}>
            {text}
        </Typography>
        <MuiDivider sx={{ flexGrow: 1 }} />
    </Box>
);

interface NewMemberDialogProps {
    onClose: () => void;
}

const NewMemberDialog: React.FC<NewMemberDialogProps> = ({ onClose }) => {
    const { brightTheme, event } = useContext(EventContext);
    const [formData, setFormData] = useState<Member>({
        id: "not_set",
        name: "",
        role: MemberRoles.ARTIST, // Always artist
        information: "",
        imagePath: "",
        socials: [] as IconLink[],
    });

    const [soundcloudUrl, setSoundcloudUrl] = useState<string>();

    // New state variables for artist search
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState<Member[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchError, setSearchError] = useState<string | null>(null);
    const [selectedArtist, setSelectedArtist] = useState<Member | null>(null);

    const [socialUrl, setSocialUrl] = useState("");
    const [socialPlatform, setSocialPlatform] = useState("");
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isExternalImage, setIsExternalImage] = useState(false);
    const [externalImageUrl, setExternalImageUrl] = useState<string | null>(null);

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev: Member) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSoundCloudUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSoundcloudUrl(value);
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        setUploadedImageFile(file);
        const imageUrl = URL.createObjectURL(file);
        setProfileImage(imageUrl);
        setExternalImageUrl(null);
        setIsExternalImage(false);
        setFormData((prev: Member) => ({
            ...prev,
            imagePath: "user_uploaded_image", // Will be replaced with actual URL after upload
        }));
    };

    // Function to fetch an image from URL and convert to File
    const fetchImageAsFile = async (imageUrl: string): Promise<File | null> => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch image: ${response.status}`);
            }

            const blob = await response.blob();
            // Generate a filename based on timestamp
            const fileName = `soundcloud_profile_${Date.now()}.jpg`;
            return new File([blob], fileName, { type: blob.type });
        } catch (error) {
            privateLog("error", "Error fetching external image:", error);
            setError("Failed to download the profile image");
            return null;
        }
    };

    const fetchProfileInfo = async () => {
        setError(null);

        if (!soundcloudUrl || !isSoundCloudProfileUrl(soundcloudUrl)) {
            setError("Please enter a valid SoundCloud URL (e.g., https://soundcloud.com/username)");
            return;
        }

        setIsLoading(true);
        try {
            const profileInfo = await extractSoundCloudProfileInfo(soundcloudUrl, event?.id || "");

            if (profileInfo && profileInfo.imageUrl) {
                // Update all the form data with SoundCloud info
                setProfileImage(profileInfo.imageUrl);
                // Store the external image URL for later download
                setExternalImageUrl(profileInfo.imageUrl);
                setIsExternalImage(true);

                // Update form with SoundCloud data
                setFormData((prev: Member) => {
                    // Create a new socials array with SoundCloud included (lowercase platform name)
                    const updatedSocials = [...(prev.socials ?? []).filter((s: IconLink) => s.name !== "soundcloud")];

                    // Add SoundCloud as a social link with lowercase platform name
                    updatedSocials.push({
                        icon: IconName.SOUNDCLOUD,
                        name: "soundcloud",
                        link: profileInfo.originalUrl,
                    });

                    return {
                        ...prev,
                        name: profileInfo.name || prev.name,
                        information: profileInfo.description || prev.information,
                        imagePath: profileInfo.imageUrl,
                        socials: updatedSocials,
                    };
                });
            } else {
                setError("Could not find profile information for this SoundCloud URL");
            }
        } catch (err: any) {
            setError("Error retrieving SoundCloud profile information");
            privateLog("info", err);
        } finally {
            setIsLoading(false);
        }
    };

    // New function to search for artists
    const searchArtists = async () => {
        if (!searchQuery.trim()) {
            setSearchError("Please enter a search term");
            return;
        }

        setIsSearching(true);
        setSearchError(null);
        setSearchResults([]);

        try {
            // Query Firestore for users with role "artist" and version 2
            const usersRef = collection(db, "users");
            const artistQuery = query(usersRef, where("role", "==", MemberRoles.ARTIST), where("version", "==", 2));

            const querySnapshot = await getDocs(artistQuery);
            const artists: Member[] = [];

            // Filter results by name (case insensitive)
            querySnapshot.forEach((doc) => {
                const data = doc.data() as Member;
                if (data.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                    artists.push(data);
                }
            });

            setSearchResults(artists);

            if (artists.length === 0) {
                setSearchError("No artists found matching your search");
            }
        } catch (err) {
            setSearchError("Error searching for artists");
            privateLog("error", "Error searching for artists:", err);
        } finally {
            setIsSearching(false);
        }
    };

    // Function to select an artist from search results
    const handleArtistSelect = async (artist: Member) => {
        setSelectedArtist(artist);
        setFormData(artist);

        // If artist has an image, retrieve and display it
        if (artist.imagePath) {
            try {
                const storage = getStorage();
                const imageRef = ref(storage, artist.imagePath);
                const imageUrl = await getDownloadURL(imageRef);
                setProfileImage(imageUrl);
            } catch (err) {
                privateLog("error", "Error loading artist image:", err);
                setProfileImage(null);
            }
        } else {
            setProfileImage(null);
        }
    };

    const handleAddSocial = () => {
        if (socialUrl && socialPlatform) {
            setFormData((prev: Member) => ({
                ...prev,
                socials: [
                    ...(prev.socials ?? []),
                    { icon: getIconNameFromString(socialPlatform) || IconName.WEBSITE, name: socialPlatform, link: socialUrl },
                ],
            }));
            setSocialUrl("");
            setSocialPlatform("");
        }
    };

    const handleSubmit = async () => {
        if (event) {
            privateLog("info", "Form submitted:", formData);

            // If we're adding an existing artist
            if (selectedArtist) {
                // Just add the reference to the event
                const eventDocRef = doc(db, "events", event.id);
                const artistDocRef = doc(db, "users", selectedArtist.id);
                await updateDoc(eventDocRef, { members: arrayUnion(artistDocRef) });
            } else {
                // Original logic for creating a new artist
                const memberColRef = collection(db, "users");
                const userDocRef = await addDoc(memberColRef, { ...formData, version: 2 });
                let imageFile: File | null = null;

                // Handle image upload - either from local file or external URL
                if (uploadedImageFile) {
                    imageFile = uploadedImageFile;
                } else if (isExternalImage && externalImageUrl) {
                    setIsLoading(true);
                    try {
                        imageFile = await fetchImageAsFile(externalImageUrl);
                    } catch (error) {
                        privateLog("error", "Error downloading external image:", error);
                    }
                    setIsLoading(false);
                }

                // Upload the image file if we have one (either local or downloaded)
                if (imageFile) {
                    const storage = getStorage();
                    const loc = `users/${userDocRef.id}/profile.jpg`;
                    const storageRef = ref(storage, loc);

                    try {
                        await uploadBytes(storageRef, imageFile);
                        // Update the document with the image URL
                        await updateDoc(userDocRef, { imagePath: loc, id: userDocRef.id });
                    } catch (error) {
                        privateLog("error", "Error uploading image:", error);
                    }
                } else {
                    // Update the document with the image URL
                    await updateDoc(userDocRef, { id: userDocRef.id });
                }

                const eventDocRef = doc(db, "events", event.id);
                await updateDoc(eventDocRef, { members: arrayUnion(userDocRef) });
            }
        }
        onClose();
    };

    return (
        <ThemeProvider theme={brightTheme}>
            <Dialog open={true} onClose={onClose}>
                <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Add New Artist
                    </Typography>

                    <Stack spacing={2}>
                        {/* SoundCloud auto-fill section */}
                        <Box sx={{ mb: 2, p: 2, border: "1px solid #eee", borderRadius: 1, backgroundColor: "#f9f9f9" }}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                Auto-fill from SoundCloud
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                                <TextField
                                    fullWidth
                                    label="SoundCloud URL"
                                    name="soundCloudUrl"
                                    placeholder="https://soundcloud.com/username"
                                    value={soundcloudUrl}
                                    onChange={handleSoundCloudUrlChange}
                                    size="small"
                                />
                                <Button variant="contained" onClick={fetchProfileInfo} disabled={isLoading || !soundcloudUrl}>
                                    {isLoading ? <CircularProgress size={24} /> : "Auto-fill"}
                                </Button>
                            </Box>
                            {error && (
                                <Alert severity="error" sx={{ mt: 1 }}>
                                    {error}
                                </Alert>
                            )}
                        </Box>

                        <Divider text="OR Search Existing Artists" />

                        {/* Search existing artists section */}
                        <Box sx={{ mb: 2, p: 2, border: "1px solid #eee", borderRadius: 1, backgroundColor: "#f9f9f9" }}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                Search for existing artists
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Search by name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    size="small"
                                />
                                <Button variant="contained" startIcon={<Search />} onClick={searchArtists} disabled={isSearching || !searchQuery}>
                                    {isSearching ? <CircularProgress size={24} /> : "Search"}
                                </Button>
                            </Box>

                            {searchError && (
                                <Alert severity="info" sx={{ mt: 1 }}>
                                    {searchError}
                                </Alert>
                            )}

                            {searchResults.length > 0 && (
                                <List sx={{ maxHeight: 200, overflow: "auto", mt: 1, border: "1px solid #eee" }}>
                                    {searchResults.map((artist) => (
                                        <ListItem
                                            key={artist.id}
                                            button
                                            onClick={() => handleArtistSelect(artist)}
                                            selected={selectedArtist?.id === artist.id}
                                            sx={{
                                                borderBottom: "1px solid #f0f0f0",
                                                "&:last-child": { borderBottom: "none" },
                                            }}
                                        >
                                            <ListItemText
                                                primary={artist.name}
                                                secondary={
                                                    getLanguageText(artist.information || "")?.substring(0, 60) +
                                                    (getLanguageText(artist.information || "")?.length > 60 ? "..." : "")
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Box>

                        <Divider text="OR Enter Manually" />

                        {/* Manual entry form */}
                        <TextField fullWidth label="Artist Name" name="name" value={formData.name} onChange={handleTextChange} required />

                        <TextField
                            fullWidth
                            label="Information / Bio"
                            name="information"
                            multiline
                            rows={3}
                            value={formData.information}
                            onChange={handleTextChange}
                        />

                        {/* Profile image section */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}>
                            <Avatar src={profileImage || undefined} alt="Artist profile" sx={{ width: 80, height: 80 }} />

                            <Box>
                                <input type="file" accept="image/*" hidden ref={fileInputRef} onChange={handleFileSelect} />
                                <Button variant="outlined" startIcon={<CloudUpload />} onClick={() => fileInputRef.current?.click()}>
                                    Upload Image
                                </Button>

                                {uploadedImageFile && (
                                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                                        {uploadedImageFile.name}
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        {/* Social links section */}
                        <Typography variant="subtitle2" sx={{ mt: 2 }}>
                            Social Links
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <TextField label="Platform" value={socialPlatform} onChange={(e) => setSocialPlatform(e.target.value)} size="small" />
                            <TextField label="URL" value={socialUrl} onChange={(e) => setSocialUrl(e.target.value)} size="small" fullWidth />
                            <Button variant="outlined" onClick={handleAddSocial}>
                                Add
                            </Button>
                        </Stack>
                        {formData.socials && formData.socials.length > 0 && (
                            <Box sx={{ mt: 1 }}>
                                {formData.socials?.map((social, index) => (
                                    <Typography
                                        key={index}
                                        variant="body2"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mb: 0.5,
                                        }}
                                    >
                                        {social.name}: {social.link}
                                    </Typography>
                                ))}
                            </Box>
                        )}

                        <Stack direction="row" spacing={2} sx={{ mt: 3 }}></Stack>
                        <Button variant="contained" onClick={handleSubmit} disabled={!selectedArtist && (!formData.name || !profileImage)}>
                            {selectedArtist ? "Add Existing Artist" : "Create New Artist"}
                        </Button>
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default NewMemberDialog;
