import { Add } from "@mui/icons-material";
import { alpha, Avatar, ButtonBase, Typography } from "@mui/material";

type Props = {
    onClick?: () => void;
    fileInputRef?: React.RefObject<HTMLInputElement>;
    handleFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    text?: string;
    width?: number;
    height?: number;
    sx?: any;
    icon?: React.ReactNode; // Add this line to support custom icons
};

const AddButton = ({ onClick, fileInputRef, handleFileChange, text, width, height, sx, icon }: Props) => {
    return (
        <ButtonBase
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Add this line
                justifyContent: "flex-start",
            }}
            onClick={() => {
                onClick && onClick();
            }}
        >
            <Avatar
                sx={(theme) => ({
                    width: width ? width : 60,
                    height: height ? height : 60,
                    backgroundColor: "background.default",
                    border: `2px solid ${theme.palette.text.primary}`,
                    "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        "&::after": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: alpha(theme.palette.primary.main, 0.5),
                        },
                    },
                    ...sx, // Spread the sx prop here
                })}
            >
                {fileInputRef && handleFileChange && (
                    <input ref={fileInputRef} type="file" hidden accept="image/*,video/*" onChange={handleFileChange} />
                )}
                {icon || (
                    <Add
                        sx={{
                            textAlign: "center",
                            color: "text.primary",
                            width: width ? width * (25 / 60) : 25,
                            height: height ? height * (25 / 60) : 25,
                        }}
                    ></Add>
                )}
            </Avatar>

            {text && (
                <Typography
                    variant="body2"
                    sx={{
                        p: 1,
                        fontSize: 10,
                        width: "100%", // Add this line
                        textAlign: "center", // Move this from the prop to sx
                    }}
                >
                    {text}
                </Typography>
            )}
        </ButtonBase>
    );
};

export default AddButton;
