import { Avatar, Box, ButtonBase, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material";
import { useAuth } from "../../../../contexts/UserContext";
import EventData from "../../../../types/EventData";
import { ContentComponent, ContentElementWireframe } from "../../../../types/bll";
import { privateLog } from "../../../../dev/PrivateConsole";
import { useState, useEffect } from "react";

type Props = {
    contentElement: ContentElementWireframe;
    updateContentElement: (updates: Partial<ContentElementWireframe>) => void;
    event: EventData;
    targetMember?: string;
};

const UploadPropertiesPage = ({ contentElement, updateContentElement, event, targetMember }: Props) => {
    const userContext = useAuth();
    const [postOnMainPage, setPostOnMainPage] = useState(true); // Step 1: State for the switch

    // Initialize members array correctly on component mount
    useEffect(() => {
        if ((userContext?.isAdmin || userContext?.member) && targetMember && postOnMainPage) {
            const initialMembers = [event.id, targetMember].filter(Boolean);
            if (userContext.member) {
                updateContentElement({ members: initialMembers, userId: userContext.member });
            } else {
                updateContentElement({ members: initialMembers });
            }
        }
    }, []);

    if (!userContext) {
        return <Box></Box>;
    }

    const handleToggle = () => {
        // Step 2: Handle switch toggle
        if ((userContext.isAdmin || userContext.member) && targetMember) {
            const membersUpdate = !postOnMainPage ? [event.id, targetMember].filter(Boolean) : [targetMember].filter(Boolean);
            setPostOnMainPage(!postOnMainPage);
            privateLog("log", "Upload", membersUpdate);
            if (userContext.member) {
                updateContentElement({ members: membersUpdate, userId: userContext.member });
            } else {
                updateContentElement({ members: membersUpdate });
            }
        }
    };

    return (
        <Box>
            <FormControl fullWidth margin="normal">
                <InputLabel id="visibility-label">Visibility</InputLabel>
                <Select
                    labelId="visibility-label"
                    value={contentElement.visibility}
                    label="Visibility"
                    onChange={(e) => updateContentElement({ visibility: e.target.value as string })}
                >
                    <MenuItem value="public">Public</MenuItem>
                    <MenuItem value="exclusive">Exclusive</MenuItem>
                </Select>
            </FormControl>
            {(userContext.isAdmin || userContext.member) && targetMember && (
                <FormControlLabel control={<Switch checked={postOnMainPage} onChange={handleToggle} />} label=" Add Post to Event Feed" />
            )}

            <FormControl fullWidth margin="normal">
                <InputLabel id="content-type-label">Content Type</InputLabel>
                <Select
                    labelId="content-type-label"
                    value={contentElement.component}
                    label="Content Type"
                    onChange={(e) =>
                        updateContentElement({
                            component: e.target.value as ContentComponent,
                        })
                    }
                >
                    {/* List all the content types you want to include */}
                    <MenuItem value={ContentComponent.IMAGES}>Images</MenuItem>
                    <MenuItem value={ContentComponent.SOUNDCLOUD}>Soundcloud</MenuItem>
                    <MenuItem value={ContentComponent.VIDEO}>Video</MenuItem>
                    {/* ... other content types */}
                </Select>
            </FormControl>
            {event.supportedLanguages ? (
                <Box>
                    <Typography mb={1}>Languages</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Switch
                            checked={contentElement.supportedLanguages !== undefined}
                            onChange={() =>
                                updateContentElement(
                                    contentElement.supportedLanguages
                                        ? { supportedLanguages: undefined, data: undefined }
                                        : { supportedLanguages: [] }
                                )
                            }
                        />
                        <Typography>Post with different languages (otherwise the text is displayed only in the default Language)</Typography>
                    </Box>
                    {contentElement.supportedLanguages !== undefined ? (
                        <Box>
                            <Typography mt={1} mb={1}>
                                Select Languages
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
                                {event.supportedLanguages.map((language, key) => (
                                    <ButtonBase
                                        key={language + key}
                                        onClick={() => {
                                            if (contentElement.supportedLanguages && contentElement.supportedLanguages.includes(language)) {
                                                // If the language is already selected, remove it
                                                updateContentElement({
                                                    supportedLanguages: contentElement.supportedLanguages.filter((lang) => lang !== language),
                                                });
                                            } else {
                                                // If the language is not selected, add it
                                                updateContentElement({
                                                    supportedLanguages: [...(contentElement.supportedLanguages || []), language],
                                                });
                                            }
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                backgroundColor: contentElement.supportedLanguages?.indexOf(language) !== -1 ? "primary.main" : "",
                                            }}
                                        >
                                            <Typography>{language}</Typography>
                                        </Avatar>
                                    </ButtonBase>
                                ))}
                            </Box>
                        </Box>
                    ) : null}
                </Box>
            ) : null}
        </Box>
    );
};

export default UploadPropertiesPage;
